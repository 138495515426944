/*
 *  @author       :  Prashant Kapoor
 *  @license      :  MIT
 *  @created      :  04/09/2021
 *  @lastModified :  04/09/2021
 *  @modifiedBy   :  Mukesh Majoka
 *  @type         :  service
 */
import * as axios from "axios";
import * as miscService from "../services/Misc";
import * as RouterService from "../services/Router";
const exceptionUrl = [
  "testimonial",
  "setting",
  "post",
  "faq",
  "job",
  "plan",
  "gstcode",
];
axios.interceptors.request.use(
  function(config) {
    const found = exceptionUrl.find((v) => config.url.includes(v));
    if (found === undefined) {
      miscService.showpreloader();
    }
    return config;
  },
  function(error) {
    console.log("error", error);
    miscService.hidepreloader();
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    miscService.hidepreloader();
    return response;
  },
  function(error) {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.force_logout === true
    ) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login";
      return;
    }
    console.error("Error in response:", error);
    miscService.hidepreloader();
    return Promise.reject(error);
  }
);
const post = async (url = "", data, token = false) => {
  if (token) {
    token = "Bearer " + token;
  }
  let response = await axios.post(url, data, {
    headers: { Authorization: token },
  });
  return response.data;
};
const put = async (url = "", data, token = false) => {
  if (token) {
    token = "Bearer " + token;
  }

  let response = await axios.put(url, data, {
    headers: { Authorization: token },
  });
  return response.data;
};
const get = async (url = "", token = false) => {
  if (token) {
    token = "Bearer " + token;
  }
  let response = await axios.get(url, { headers: { Authorization: token } });
  return response.data;
};
const deleteData = async (url = "", token = false) => {
  if (token) {
    token = "Bearer " + token;
  }
  let response = await axios.delete(url, { headers: { Authorization: token } });
  return response.data;
};
export async function downloadFile(fileUrl, data, token = false) {
  if (token) {
    token = "Bearer " + token;
  }
  axios
    .post(fileUrl, data, {
      responseType: "blob",
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
    })
    .then(function(response) {
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = data.fileName;
      // data.fileName?.indexOf(".pdf") === -1
      //   ? data.fileName?.indexOf(".json") != -1
      //     ? data.fileName
      //     : data.fileName
      //   : data.fileName;
      link.click();
    });
}
export async function downloadTcpFile(fileUrl, token = false) {
  if (token) {
    console.log("token is " + token);
    token = "Bearer " + token;
  }
  axios
    .get(fileUrl, {
      responseType: "blob",
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
    })
    .then(function(response) {
      console.log(JSON.parse(JSON.stringify(response.headers)));
      const responseHeaders = JSON.parse(JSON.stringify(response.headers));
      console.log("fileName", responseHeaders.downloadfilename);
      const fileName = responseHeaders.downloadfilename;
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    });
}
export { post, get, deleteData, put };
