/*
 *  @author       :  Prashant Kapoor
 *  @license      :  MIT
 *  @created      :  04/09/2021
 *  @lastModified :  04/09/2021
 *  @modifiedBy   :  Mukesh Majoka
 *  @type         :  service
 */
import * as opsService from "./Ops";
import * as dataConstants from "../constants/Data";
const addUser = (data, token) => {
    return opsService.post(dataConstants.base.api + "user/save", data, token);
  },
  saveMobileAsUser = (mobile) => {
    return opsService.get(
      dataConstants.base.api + "user/save/mobile/" + mobile
    );
  },
  updateUser = async (data, token) => {
    try {
      let result = await opsService.put(
        dataConstants.base.api + `user/update`,
        data,
        token
      );
      return result;
    } catch (e) {
      return { status: false, data: {}, message: e.message };
    }
  },
  updateUserRole = async (data, token) => {
    try {
      let result = await opsService.put(
        dataConstants.base.api + `user/role/update`,
        data,
        token
      );
      return result;
    } catch (e) {
      return { status: false, data: {}, message: e.message };
    }
  },
  addDaysForDataExport = async (data, token) => {
    try {
      let result = await opsService.post(
        dataConstants.base.api + `admin/exportDays/add`,
        data,
        token
      );
      return result;
    } catch (e) {
      return { status: false, data: {}, message: e.message };
    }
  },
  getExportDaysById = async (id, token) => {
    return await opsService.get(
      dataConstants.base.api + "admin/exportDays/" + id,
      token
    );
  },
  restricted = async (data, token) => {
    try {
      let result = await opsService.put(
        dataConstants.base.api + `/admin/ip/restricted`,
        data,
        token
      );
      return result;
    } catch (e) {
      return { status: false, data: {}, message: e.message };
    }
  },
  registerUser = async (data, token) => {
    try {
      let result = await opsService.post(
        dataConstants.base.api + `user/register`,
        data,
        token
      );
      return result;
    } catch (e) {
      return { status: false, data: {}, message: e.message };
    }
  },
  verifyOtp = async (data) => {
    try {
      let result = await opsService.post(
        dataConstants.base.api + `user/verifyOtp`,
        data
      );
      return result;
    } catch (e) {
      return { status: false, data: {}, message: e.message };
    }
  },
  getUserById = async (id, token) => {
    return await opsService.get(
      dataConstants.base.api + "user/get/" + id,
      token
    );
  },
  getAdminUserById = async (id, token) => {
    return await opsService.get(
      dataConstants.base.api + "admin/users/list/" + id,
      token
    );
  },
  getUser = async (id, token) => {
    return await opsService.get(
      dataConstants.base.api + "user/get/" + id,
      token
    );
  },
  getUserPlan = async (token) => {
    return await opsService.get(dataConstants.base.api + "user/getPlan", token);
  },
  ifAdminRoleDisabled = async (token) => {
    return await opsService.get(
      dataConstants.base.api + "check/admin/role/validity",
      token
    );
  },
  getAdminUserPlan = async (id, token) => {
    return await opsService.get(
      dataConstants.base.api + "admin/user/getPlan/" + id,
      token
    );
  },
  fetchUserbyquery = async (data, token) => {
    console.log("datafetchUserbyquery", data);
    return await opsService.post(
      dataConstants.base.api + "admin/fetchUserbyquery/get",
      data,
      token
    );
  },
  deleteUser = async (id, token) => {
    return await opsService.deleteData(
      dataConstants.base.api + "user/delete/" + id,
      token
    );
  },
  deleteIp = async (id, token) => {
    return await opsService.deleteData(
      dataConstants.base.api + "admin/ip/delete/" + id,
      token
    );
  },
  activateUser = async (id, token) => {
    return await opsService.deleteData(
      dataConstants.base.api + "user/activate/" + id,
      token
    );
  },
  getUsers = async (token) => {
    return await opsService.get(dataConstants.base.api + "user/list", token);
  },
  logout = () => {
    localStorage.removeItem("state");
    return true;
  },
  login = (userData) => {
    return (dispatch) => {
      dispatch({
        type: "LOGIN",
        data: userData,
      });
    };
  },
  getLoggedInUserData = () => {
    let userData =
      localStorage.getItem("tempLogin") === "true"
        ? localStorage.getItem("tempUserData")
        : localStorage.getItem("state");
    return userData === null ? userData : JSON.parse(userData);
  },
  updateLocalUser = (data) => {
    let existState = JSON.parse(localStorage.getItem("state"));
    for (var key in data) {
      existState[key] = data[key];
    }
    localStorage.setItem("state", JSON.stringify(existState));
  },
  isAdmin = () => {
    if (localStorage.getItem("state") !== null) {
      return getLoggedInUserData().role === "admin";
    } else {
      return false;
    }
  },
  isSuperVisor = () => {
    if (localStorage.getItem("state") !== null) {
      return getLoggedInUserData().role === "supervisor";
    } else {
      return false;
    }
  },
  sendOtp = async (data, token = false) => {
    console.log("data####", data);
    const formData = new FormData();
    if (data.mobile) {
      formData.append("mobile", data.mobile);
    } else {
      formData.append("email", data.email);
    }
    let url = !token ? `user/sendotp` : `user/sendupdateotp`;
    try {
      if (data.business_mobile) {
        let result = await opsService.post(
          dataConstants.base.api + url,
          data,
          token
        );
        return result;
      } else {
        let result = await opsService.post(
          dataConstants.base.api + url,
          formData,
          token
        );
        return result;
      }
    } catch (e) {
      return { status: false, data: {}, message: e.message };
    }
  },
  resentLoginOtp = async (data) => {
    try {
      let result = await opsService.post(
        dataConstants.base.api + `user/resentLoginOtp`,
        data
      );
      return result;
    } catch (e) {
      return { status: false, data: {}, message: e.message };
    }
  },
  sendInvite = async (data, token) => {
    try {
      let result = await opsService.post(
        dataConstants.base.api + `sendReferralInvite`,
        data,
        token
      );
      return result;
    } catch (e) {
      return { status: false, data: {}, message: e.message };
    }
  },
  getWallet = async (token) => {
    return new Promise((resolve, reject) => {
      opsService
        .get(dataConstants.base.api + "user/getwallet", token)
        .then((data) => resolve(data))
        .catch((e) => reject(e));
    });
  },
  fetchAllUsers = async (token) => {
    try {
      console.log("fetchAllUsers data#####");
      let result = await opsService.get(
        dataConstants.base.api + `admin/users/list`,
        token
      );
      return result;
    } catch (e) {
      return e;
    }
  },
  fetchAllAdminUsers = async (data, token) => {
    console.log("data#####", data);
    try {
      let result = await opsService.post(
        dataConstants.base.api + `admin/users/list`,
        data,
        token
      );
      return result;
    } catch (e) {
      return e;
    }
  },
  fetchAllAdmins = async (postData, token) => {
    try {
      let result = await opsService.post(
        dataConstants.base.api + `admin/admins/list`,
        postData,
        token
      );
      return result;
    } catch (e) {
      return e;
    }
  },
  fetchAllIp = async (token) => {
    try {
      let result = await opsService.get(
        dataConstants.base.api + "admin/ip/list",
        token
      );
      return result;
    } catch (e) {
      return e;
    }
  },
  fetchByPhone = async (phone, token) => {
    let result = await opsService.get(
      dataConstants.base.api + `user/fetchByPhone/` + phone,
      token
    );
    return result;
  },
  fetchByEmail = async (email, token) => {
    let result = await opsService.get(
      dataConstants.base.api + `user/fetchByEmail/` + email,
      token
    );
    return result;
  },
  upgradeUserSubscription = async (data, token) => {
    return await opsService.post(
      dataConstants.base.api + `admin/user/upgrade/subscription`,
      data,
      token
    );
  },
  saveTempOtp = async (data, token) => {
    return await opsService.post(
      dataConstants.base.api + `admin/save/TempOtp`,
      data,
      token
    );
  },
  getTempOtp = async (role, token) => {
    let result = await opsService.get(
      dataConstants.base.api + `admin/getTempOTP/` + role,
      token
    );
    return result;
  },
  getUserDataByMobile = async (mobile, token) => {
    let result = await opsService.get(
      dataConstants.base.api + `admin/getUserData/` + mobile,
      token
    );
    return result;
  },
  getTokenAuthStatusForRedirection = async (token) => {
    let result = await opsService.get(
      dataConstants.base.api + `redirect/auth`,
      token
    );
    return result;
  },
  addIp = async (object, token) => {
    try {
      return await opsService.post(
        dataConstants.base.api + "admin/ip/add",
        object,
        token
      );
    } catch (e) {
      return { status: false, data: {}, message: e.message };
    }
  },
  expireUserPlan = async (object, token) => {
    try {
      return await opsService.post(
        dataConstants.base.api + "admin/user/expire",
        object,
        token
      );
    } catch (e) {
      return {
        status: false,
        data: {},
        message: e.message,
      };
    }
  },
  getCountUserList = async (object, token) => {
    try {
      return await opsService.post(
        dataConstants.base.api + "admin/users/list/count",
        object,
        token
      );
    } catch (e) {
      return {
        status: false,
        data: {},
        message: e.message,
      };
    }
  },
  getTransactionsIds = async (object, token) => {
    try {
      return await opsService.post(
        dataConstants.base.api + "admin/order/getTransactionIds",
        object,
        token
      );
    } catch (e) {
      return {
        status: false,
        data: {},
        message: e.message,
      };
    }
  },
  getWalletBalance = async (id, token) => {
    try {
      return await opsService.get(
        dataConstants.base.api + `wallet/balance/${id}/true/false`,
        token
      );
    } catch (e) {
      return {
        status: false,
        data: {},
        message: e.message,
      };
    }
  };
export {
  updateLocalUser,
  updateUserRole,
  verifyOtp,
  getLoggedInUserData,
  logout,
  login,
  sendOtp,
  sendInvite,
  getUsers,
  getUser,
  getUserById,
  getAdminUserById,
  fetchUserbyquery,
  addUser,
  updateUser,
  deleteUser,
  isAdmin,
  isSuperVisor,
  getUserPlan,
  getAdminUserPlan,
  registerUser,
  getWallet,
  fetchAllUsers,
  fetchAllAdminUsers,
  fetchAllAdmins,
  fetchByPhone,
  fetchByEmail,
  upgradeUserSubscription,
  resentLoginOtp,
  activateUser,
  saveMobileAsUser,
  saveTempOtp,
  getTempOtp,
  getUserDataByMobile,
  getTokenAuthStatusForRedirection,
  fetchAllIp,
  deleteIp,
  addIp,
  restricted,
  addDaysForDataExport,
  getExportDaysById,
  expireUserPlan,
  getCountUserList,
  ifAdminRoleDisabled,
  getTransactionsIds,
  getWalletBalance,
};
